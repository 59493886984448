
// colors
$red: #ef4937;
$title: #303030;
$bg: #fef4d1;
$yel: #e37a1c;
$link: #512f25;
$blue: #4b5492;
$lblue: #2fc9e0;

//background: url("../img/icons/lupa.png");

//box-shadow: inset 0 0 1em gold;

//box-shadow: inset 0 0 10px #000000;

