@mixin transite($property: all, $duration: .3s, $timing: easy-in){
  transition-property: $property;
  transition-duration: $duration;
  transition-timing-function: $timing;
}

@mixin respond-to($point) {
  @if $point == 320 {
    @media only screen and (max-width: 767px) { @content; }
  }
  @else if $point == 768 {
    @media only screen and (max-width: 959px) { @content; }
  }
  @else if $point == 960 {
    @media only screen and (max-width: 1199px) { @content; }
  }
}

@mixin icon($icon){
  background: url('../img/icons/#{$icon}') no-repeat center center;
}

@mixin link(){
  display: inline-block;
  padding: 7px 10px;
  background: $blue;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;

  &:hover{
    background: $red;
    @include transite();
  }
}

@mixin input(){

}

@mixin item-list(){
  .item-list{
    ul.pager{
      li{
        a{
          color: $blue;
          &:hover{
            color: $red;
            @include transite();
          }
        }
        &.pager-current{
          color: $red;
        }
      }
    }
  }
}

@mixin text-editor(){
  a{
    color: $blue;
    font-weight: 700;

    &:hover{
      color: $red;
      @include transite();
    }
  }
  ul{
    list-style: initial;

    li{
      margin-left: 20px;
      margin-bottom: 5px;
    }
  }

  ol{
    list-style: decimal;
    li{
      margin-left: 20px;
      margin-bottom: 5px;
    }

  }

  img{
    max-width: 100%;
    height: auto;
    margin: 10px;
    &:hover{
      filter:grayscale(.8);
      @include transite();
    }
  }

  table{
    width: 100%!important;

    @include respond-to(768){
      overflow-x: scroll;
      height: auto !important;
      display: block;
      width: 466px !important;
    }
    @include respond-to(320){
      width: 280px !important;
    }
    //border:1px solid $title;
    tr{
      //border:1px solid $title;
      border-bottom:1px solid grey;

      &:last-of-type{

        td{
          border-bottom:1px solid grey;
        }
      }
    }

    td{
      padding: 5px 10px;
      border:1px solid grey;
      border-right: none;
      border-bottom: none;

      &:last-of-type{
        border-right:1px solid grey;
      }
    }
  }

  p{
    margin-bottom: 10px;

    img{
      margin: 10px;
      &:hover{
        filter:grayscale(.8);
        @include transite();
      }
    }
  }

  strong{
    font-weight: 700;
  }

  em{
    font-style: italic;
  }

  br {
    line-height: 150%;
  }

  .text{
    p{
      a{
        color: $blue;
        font-weight: 700;
        &:hover{
          color: $title;
          @include transite();
        }
      }
    }
  }

  iframe{
    width: 668px;
    height: 400px;
    margin-top: 20px;
    margin-bottom: 20px;
    @include respond-to(768){
      width: 470px;
    }
    @include respond-to(320){
      width: 280px;
    }
  }

  .images{
    margin-top: 20px;
    img{
      margin-right: 20px;
      margin-bottom: 20px;
      &:hover{
        filter:grayscale(.5);
        @include transite();
      }
    }
  }

}

@mixin item-list(){
  ul.pager{
    margin-top: 10px;
    li a{
      color: $title;
      font-weight: 700;
      &:hover{
        color: $blue;
        @include transite();
      }
    }
    .pager-current{
      color: $blue;
    }
  }
}