@import "helpers/reset";
@import "helpers/slick";
@import "helpers/slick-theme";
@import "helpers/mixins";
@import "helpers/vars";

//@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,700&subset=cyrillic-ext');
@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,400i,700&subset=cyrillic-ext');
//common rules

* {
  font-family: 'PT Sans', sans-serif;
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1}
}

body{
  padding: 0;
  margin: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #e8f6ff;

  @include transite(all);
  transition-duration: 500ms;

  &.is-active{
    overflow-y: auto;
    animation-name: fadeIn;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    visibility: visible;
  }

  &.is-fixed{
    overflow: hidden;
  }
 }

header{
  background: #fff;
}

/*............. mobile head - top ..............*/
.mobile-head{
  width: 280px;
  margin: 0 auto;
  position: relative;
  display: none;
  @include respond-to(320){
    display: block;
  }
  .mobile-logo{
    padding-top: 8px;
  }
  .control-buttons{
    display: flex;
    justify-content: space-around;

    #menu-button, #catalog-button{
      width: 50%;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: $blue;
      color: #fff;
      text-transform: uppercase;
      font-weight: 700;
      //&:hover{
      //  background: $red;
      //  @include transite();
      //}
      &.is-active{
        color: $red;
      }
    }
    #menu-button{
      border-right:1px solid #fff;
    }
  }

  .mobile-menu{
    padding-bottom: 20px;
    position: absolute;
    z-index: 30;
    background: #fff;
    width: 100%;
    display: none;
    box-shadow: 3px 4px 10px $title;

    ul.menu{
      text-align: center;
      margin-bottom: 10px;
      li a{
        display: block;
        padding-top: 8px;
        border-bottom: 8px;
        color: $title;
        font-size: 18px;
        &:hover{
          color: $red;
          text-decoration: underline;
          @include transite();
        }
      }
    }
    
    .region-mobile-buttons{
      #block-multiblock-2{
        .content{
          p{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            a:nth-of-type(1){
              text-decoration: underline;
              font-size: 18px;
              font-weight: 700;
              color: $title;
              margin-bottom: 5px;
              &:hover{
                color: $red;
                @include transite();
              }
            }
            a:nth-of-type(2){
              margin-top: 5px;
              margin-bottom: 15px;
              display: inline-block;
              padding: 7px 10px;
              background: $red;
              color: #fff;
              text-transform: uppercase;
              font-weight: 700;
              font-size: 14px;
              &:hover{
                background: $blue;
                @include transite();
              }
            }
          }
        }
      }
    }

    .region-mobile-contacts{
      #block-multiblock-1{
        .content{
          p{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            a{
              color: $title;
              font-size: 18px;
              &:nth-of-type(2){
                margin-top: -15px;
              }
            }
          }
        }
      }
    }

    .region-mobile-search{
      #block-multiblock-3{
        .content{
          .views-exposed-widgets{
            display: flex;
            justify-content: center;
            padding-top: 10px;
          }
          input[type="text"]{
            width: 200px;
            height: 30px;
            padding-left: 10px;
            border:1px solid $blue;
            border-right: none;

          }
          input[type="submit"]{
            width: 30px;
            height: 30px;
            text-indent:-99999px;
            border:1px solid $blue;
            border-left: none;
            background: url("../img/icons/bloop.png");
            background-repeat: no-repeat;
            background-position: 50% 50%;
          }
        }
      }
    }
  }//mobile-menu - end

  /*................. mobile-sidebar - top .........*/
  .mobile-sidebar{
    position: absolute;
    z-index: 30;
    display: none;
    width: 100%;
    .region-mobile-catalog{
      #block-multiblock-4{
        .block-title{
          display: none;
        }

        .content{
          ul.menu{
            max-height:300px;
            overflow-y: scroll;
            li a{
              display: block;
              padding: 10px;
              color: #fff;
              background: $blue;
              border-bottom: 1px solid #fff;
              &:hover{
                background: $red;
                @include transite();
              }
            }
            .collapsed{
              //position: relative;
              //&:after{
              //  content: "\276F";
              //  color: #fff;
              //  position: absolute;
              //  top: calc(50% - 10px);
              //  right: 3px;
              //}
            }
            .expanded{
              >ul{
                li a{
                  padding-left: 25px;
                }
              }
            }
          }
        }
      }
    }
  }
  /*................. mobile-sidebar - end .........*/
}

/*............. mobile head - end ..............*/

/*................... search-wrap -top................*/
.search-wrap{
  background: $blue;
  position: relative;
  display: none;

  .close-it{
    position: absolute;
    font-size: 40px;
    color: #ccc;
    transform: rotate(45deg);
    right: 30px;
    top: 10px;
    cursor: pointer;
  }

  .region-feed-search{
    width: 960px;
    height: 140px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @include respond-to(768){
      width: 720px;
      height: 100px;
    }

    #block-views-exp-allsearch-page{

      .content{
        input[type="text"]{
          width: 380px;
          height: 40px;
          font-size: 18px;
          background: transparent;
          border-bottom: 1px solid #ccc;
          color: #ccc;
          padding-left: 10px;
        }
        input[type="submit"]{
          height: 40px;
          width: 40px;
          border-bottom: 1px solid #ccc;
          background: transparent;
          background: url("../img/icons/wloop.png") center center;
          background-repeat: no-repeat;
          text-indent:-9999px;
        }
      }
    }
  }
}
/*................... search-wrap -end................*/


//............ menu top.............//
.menu-wrapper{
  width: 920px;
  margin: 0 auto;
  position: relative;
  border:1px solid $blue;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include respond-to(768){
    width: 720px;
  }
  @include respond-to(320){
    display: none;
  }

  .menu{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    li{
       a{
          display: flex;
          align-items: center;
          font-size: 16px;
          color: $title;
          height: 50px;
          padding: 0 10px;
          border-right:1px solid $blue;
          &:hover{
            background-color: $blue;
            color: #fff;
          @include transite();
          }

         @include respond-to(768){
           padding: 0 4px;
           font-size: 15px;
         }
        }


      &.active-trail{
        >a{
          background-color: $blue;
          color: #fff;
        }
      }
    }
    

  }

  .search-call{
    height: 50px;
    width: 50px;
    background: url("../img/icons/bloop.png"), #fef4d1;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border-left:1px solid $blue;
    cursor: pointer;
    &:hover{
      background: url("../img/icons/bloop.png"), $red;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      @include transite();
    }
  }


}
//............ menu bottom .............//

//............ feedback top ............//
.feedback{
  width: 960px;
  height: 115px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  display:flex;
  justify-content: space-between;
  align-items:center;
  @include respond-to(768){
    width: 720px;
    padding-left: 0;
    padding-right: 0;
    height: auto;
  }
  @include respond-to(320){
    display: none;
  }

  .logo{
    width: 260px;
    padding-top: 8px;
    @include respond-to(768){
      width: 240px;
    }
    a{
      &:hover{
        filter: opacity(.7);
      }
    }
    img{
      max-width:100%;
      height:auto;
    }
  }
}

.region-feed-center{
  width: 270px;
  display: flex;
  align-items: center;
  //slogan
  #block-block-3{
    .content{
      font-weight: 700;
      p{
        a{
          color: $blue;
          &:hover{
            color: $red;
            @include transite();
          }
        }
      }
    }
  }
  @include respond-to(768){
    width: 230px;
  }
}

.region-feed-right{
  //phones
  #block-block-9{
    @include respond-to(768){
      width: 240px;
    }
    .content{
      text-align: right;
      a{
        color: $title;
        font-size: 18px;
        font-weight: 700;
        &:hover{
          color: $blue;
          @include transite();
        }
        @include respond-to(768){
          font-size: 16px;
        }
      }
    }
  }

  //buttons
  #block-block-2{
    margin-top: 10px;

    @include respond-to(768){
      width: 240px;
    }
    .content{
      p{

        a:first-of-type{
          color: $blue;
          text-decoration: underline;
          margin-right: 10px;
          &:hover{
            color: $red;
            @include transite();
          }
        }
        a:nth-of-type(2n){
          display: inline-block;
          padding: 10px 16px;
          background: $red;
          color: #fff;
          text-transform: uppercase;
          font-weight: 700;
          margin-left: 10px;

          &:hover{
            background: $blue;
            @include transite();
          }
          @include respond-to(768){
            padding: 10px;
            font-size: 11px;
          }
        }
      }

    }
  }
}
//............ feedback bottom .........//

.slider-bg{
  background: url("../img/icons/wood.jpg");
  background-size: cover;
  border-top: 20px solid #fff;
  @include respond-to(320){
    display: none;
  }

/*.................. sliders-wrap - top .....................*/
.sliders-wrap{
  width: 920px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include respond-to(768){
    width: 720px;
  }
  #block-views-main-slider-block{
    width: 620px;
    height: 282px;
    position: relative;
    @include respond-to(768){
      width: 496px;
      height: 252px;
    }

    .content{
      .view-id-main_slider{
        .slick-dotted.slick-slider{
          margin-bottom: -5px;
          .slick-arrow{
            opacity: 0;
          }
        }
      }

      .slick-slide{

        .views-row{
          position: relative;
          .views-field{
            .image{
              width: 620px;
              height: 278px;
              margin: 0 auto;
              @include respond-to(768){
                width: 496px;
              }
              img{
                max-width:100%;
                height:auto;
              }
            }
          }
        }
      }
      //........ slick dots ...........//
      .slick-dots{
        position: absolute;
        z-index: 20;
        height: 20px;
        width: 175px;
        bottom: 23px;
        @include respond-to(768){
          bottom: 52px;
        }

        li{
          button{
            background-color: #ccc;
            height: 14px;
            width: 14px;
            border-radius: 100%;
            &:before{
              content: "";
            }
            &:hover{
              background: $red;
              @include transite();
            }
          }
        }
        li.slick-active{
          button{
            background: $red;
          }
        }
      }
      //........ slick dots ...........//

    }
  }
  //...... slider inner block .......//
  #block-views-main-slider-block{
    .container{
      display: none;
      position: absolute;
      width: 610px;
      height: 385px;
      top: 65px;
      left: 70px;
      background-color: rgba(0, 0, 0, 0.7);
      //display: flex;
      //flex-direction:column;

      .title{
        text-transform: uppercase;
        font-size: 32px;
        font-weight: 700;
        color: #fff;
        width: 300px;
        height:auto;
        margin: 30px 0 0 40px;
      }
      .text{
        width: 340px;
        height:108px;
        overflow: hidden;
        color: #fff;
        line-height: 1.2;
        font-size: 18px;
        font-weight: 700;
        margin: 20px 0 0 40px;
      }
      .more a{
        display: block;
        width: 175px;
        height: 40px;
        color: #fff;
        font-weight: 700;
        background-color: $yel;
        padding: 10px 0 0 13px;
        text-transform: uppercase;
        margin: 20px 0 0 40px;
        &:hover{
          background-color: $blue;
          @include transite();
        }

      }
    }
  }

  //........ main slider - bottom .....//

  //mini slider - top

  .region-mini-slider{
    #block-views-minislider-block{
      width: 282px;
      @include respond-to(768){
        width: 217px;
      }

      .content{
        .view-id-minislider{
          .slick-dotted.slick-slider{
            margin-bottom: -5px;
            .views-row{
              .image{
                @include respond-to(768){
                  width: 217px;
                  height: 250px;
                }

                img{
                  width: 100%;
                  height: auto;
                  @include respond-to(768){
                    width: 116%;
                    margin-left: -20px;
                  }
                }
              }
            }
            .slick-arrow{
              opacity: 0;
            }
          }
        }
      }
    }
  }
}

}

/*................ reklama block - top ..................*/
.middle-wrap{
  background: url("../img/icons/wood.jpg");
  background-size: cover;

  .region-middle-region{
    width: 920px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    @include respond-to(768){
      width: 720px;
      padding-bottom: 0;
      justify-content: space-between;
    }
    @include respond-to(320){
      width: 280px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center
    }

    #block-block-10, #block-block-11, #block-block-12{
      width: 294px;
      height: 158px;
      @include respond-to(768){
        width: 230px;
        height: 150px;
      }
      .content{
        p{
          img{
            width: 100%;
            height: auto;
            &:hover{
              filter:grayscale(.8);
              @include transite();
            }
          }
        }
      }
    }
  }
}


/*................ reklama block - end ..................*/

/*............... footer - top .................*/
footer{
  @include respond-to(320){
    display: none;
  }
  .footer-center{
    width: 920px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0;
    @include respond-to(768){
      width: 720px;
    }
    @include respond-to(320){
      display: none;
    }

    ul.menu{
      width: 356px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      li {
        margin-bottom: 10px;
        width: 50%;
        a{
          color: $title;
          &:hover{
            color: $blue;
            text-decoration: underline;
            @include transite();
          }
        }
      }
    }

    .region-footer-contact{
      #block-block-6{
        width: 300px;

        .block-title{
          margin-bottom: 10px;
        }

        .content{
          .number{

          }
          .address{
            margin-top: 10px;
          }
          a{
            color: $title;
            font-weight: 700;
            &:hover{
              color: $blue;
              @include transite();
            }
          }
        }
      }
    }
  }
}

/*............... footer - bottom .................*/

/*............... copyright - top .............*/
.copyright{
  border-top:1px solid #ccc;
  width: 920px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  @include respond-to(768){
    width: 720px;
  }
  @include respond-to(320){
    width: 280px;
  }

  #block-block-14{

  }

  .copy-logo{
    opacity:0;
    width: 150px;
    height: 40px;
    display: block;
    @include respond-to(320){
      width: 120px;
    }
    img{
      width: 100%;
      height: auto;
    }
  }
}
/*............... copyright - end .............*/

//////////////////////////////////////////
//////////// INNER PAGES /////////////////
//////////////////////////////////////////

.breadcrumb{
  width: 920px;
  margin: 0 auto;
  padding-top: 10px;
  @include respond-to(768){
    width: 720px;
  }
  @include respond-to(320){
    display: none;
  }
  a{
    color: $title;
    
  }
  .last{
    color: grey;
    a{
      color: grey;
    }
  }
}

.main__content{
  width: 920px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height:calc(100vh - 386px);
  border-bottom: 1px solid #ccc;
  position: relative;
  @include respond-to(768){
    width: 720px;
  }
  @include respond-to(320){
    width: 280px;
    min-height:calc(100vh - 256px);
  }

  /*................ sidebar - top ..............*/
  .region-sidebar{
    width: 220px;
    margin-top: 20px;

    @include respond-to(320){
      display: none;
    }

    #block-menu-menu-katalog{
      .block-title{
        height: 40px;
        width: 220px;
        display: flex;
        align-items: center;
        color: $link;
        background: $bg;
        padding-left: 17px;
        font-weight: 700;
        margin-bottom: 1px;
      }

        ul.menu{
          background: $bg;
          li{

            a{
              display: block;
              padding: 10px;
              line-height: 1;
              background: $bg;
              color: $link;
              position: relative;
              border-bottom:1px solid #fff;

              &:hover{
                background: $red;
                color: #fff;
                @include transite();
              }
            }
            &.expanded{
              >ul.menu{
                >li{
                  a{
                    padding-left: 20px;

                  }
                  &.expanded{
                    >ul{
                      >li{
                        a{
                          padding-left: 30px;
                        }
                      }
                    }
                  }
                }
              }
            }
            &.active-trail{
              >a{
                color: $blue;
              }
            }

            &.collapsed{
              position: relative;
              &:after{
                content: "\276F";
                position: absolute;
                right: 5px;
                top:calc(50% - 10px);
                color: $link;
              }
            }
            &.expanded.active-trail > a{
              position: relative;
              &:after{
                content: "\276F";
                position: absolute;
                right: 4px;
                top:calc(50% - 10px);
                transform: rotate(90deg);
              }
            }
          }


        }

    }

    /*.............. reklama block - top ...............*/
    #block-block-13{
      margin-top: 20px;
      width: 220px;
      .content{
        p{
          img{
            width: 100%;
            height: auto;
            &:hover{
              filter:grayscale(.8);
              @include transite();
            }
          }
        }
      }
    }

    /*.............. reklama block - end ...............*/
  }//sidebar end



  /*................ container - top ..............*/
  .container{
    width: 680px;
    margin-top: 20px;
    margin-bottom: 24px;
    @include respond-to(768){
      width: 470px;
    }
    @include respond-to(320){
      width: 280px;

    }

    #page-title{
      font-size: 28px;
      text-transform: uppercase;
      color: $title;
      font-weight: 700;
      @include respond-to(768){
        font-size: 22px;
      }
    }

    .region-front-catalog, .region-content{
      #block-views-favoritesonfront-block, #block-system-main{
        .block-title{
          height: 40px;
          display: flex;
          align-items: center;
          border-top: 1px solid $blue;
          border-bottom: 1px solid $blue;
          margin-bottom: 15px;
          padding-left: 5px;
          color: $red;
          font-weight: 700;
          width: 680px;
          @include respond-to(768){
            width: 470px;
          }
          @include respond-to(320){
            width: 280px;
          }
        }

        .content{
          .view-favoritesonfront.view-display-id-block, .view-taxonomy-term.view-display-id-page{
            @include item-list();

            .view-header{
              margin-top: 20px;
              margin-bottom: 20px;
              @include text-editor();

            }
            .view-content{
              display: flex;
              flex-wrap: wrap;
              //justify-content: flex-start;
              justify-content: space-between;
              @include respond-to(768){
                justify-content: space-between;
              }
              @include respond-to(320){
                justify-content: center;
              }
              .node-item.view-mode-teaser{
                width: 215px;
                border:1px solid $blue;
                //margin-right: 15px;
                margin-bottom: 15px;
                padding-top: 15px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                &:after{
                  display: none;
                }
                @include respond-to(768){
                  margin-right: 0;
                  width: 225px;
                }
                &:hover{
                  border:1px solid $red;
                  @include transite();
                  .image{
                    filter:grayscale(.8);
                    @include transite();
                  }
                  h2 a{
                    color: $blue;
                    @include transite();
                  }
                  .more{
                    a{
                      background: $red;
                      @include transite();
                    }

                  }
                }//hover end

                .image{
                  text-align: center;
                }
                
                h2 a{
                  display: block;
                  font-size: 16px;
                  color: $title;
                  width: 180px;
                  margin: 0 auto;
                  line-height: 1;
                  height: 50px;
                  overflow: hidden;
                  text-align: center;
                }

                .price{
                  width: 180px;
                  margin: 0 auto;
                  text-align: center;
                  color: $red;
                  font-size: 22px;
                }

                .more{
                  text-align: center;
                  a{
                    margin: 10px auto;
                    text-align: center;
                    @include link();
                  }


                }
              }
            }
          }
        }
      }
    }

    //control tabs
    .tabs{
      a{
        color: $title;
      }
    }//control tabs

    #block-system-main{
      margin-top: 20px;

      .content{
        margin-bottom: 30px;

        #user-login, #user-pass{
          input[type="text"]{
            width: 280px;
            height: 30px;
            border:1px solid $title;
            padding-left: 10px;
          }
          input[type="password"]{
            width: 280px;
            height: 30px;
            border:1px solid $title;
            padding-left: 10px;
          }
          input[type="submit"]{
            display: inline-block;
            background: $title;
            color: #fff;
            text-transform: uppercase;
            font-weight: 700;
            padding: 10px 20px;
            margin-top: 10px;
            &:hover{
              background: $blue;
              @include transite();
            }
          }
        }

        /*........ item full page - top .......*/
        .node-item.view-mode-full{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .group-left{
            width: 50%;
            @include respond-to(320){
              width: 100%;
            }
            &:hover{
              filter:grayscale(.8);
              @include transite();
            }
          }
          .group-right{
            width: 50%;
            @include respond-to(320){
              width: 100%;
            }
            .price{
              margin-bottom: 10px;
              color: $blue;
              text-transform: uppercase;
              .metka{
                text-transform: lowercase;
                font-weight: 700;
              }
            }

            .more{
              a{
                @include link();
              }
            }
          }
          .group-footer{
            width: 100%;
            margin-top: 20px;
            @include text-editor();
          }
        }

        /*........ item full page - end .......*/

        /*............... inner preview news - top................*/
        .view-news.view-display-id-page{
          @include item-list();
          .view-content{
            .node-article.view-mode-shortcut{
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 20px;

              .group-left{
                width: 200px;
                &:hover{
                  filter:grayscale(.8);
                  @include transite();
                }

                @include respond-to(320){
                  width: 280px;
                  text-align: center;
                }
              }
              .group-right{
                width: 470px;
                @include respond-to(768){
                  width: 260px;
                }
                @include respond-to(320){
                  width: 280px;
                }
                .title{
                  height:38px;
                  overflow: hidden;
                  a{
                    color: $title;
                    text-transform: uppercase;
                    font-weight: 700;
                    &:hover{
                      color: $blue;
                      @include transite();
                    }
                  }
                  @include respond-to(320){
                    text-align: center;
                    margin-top: 10px;
                  };
                }
                .text{
                  margin-top: 7px;
                  height: 100px;
                  line-height: 1.2;
                  overflow: hidden;
                  p{
                    img{
                      display: none;
                    }
                    a{
                      color: $red;
                      text-decoration: underline;
                      &:hover{
                        color: $blue;
                        @include transite();
                      }
                    }
                  }
                }
                .more-link{
                  margin-top: 7px;
                  a{
                    color: $red;
                    text-decoration: underline;
                    &:hover{
                      color: $blue;
                      @include transite();
                    }
                  }
                }
              }
            }
          }
        }

        /*............... inner preview news - bottom ............*/



        /*................ simple pages - top ..................*/
        .node-page.view-mode-full, .node-article.view-mode-full{
          @include text-editor();

          .images{
            img{
              margin-right: 15px;
              margin-bottom: 15px;
              &:hover{
                filter:grayscale(.8);
                @include transite();
              }
            }
            @include respond-to(320){
              text-align: center;
            }
          }

          .text{
            @include text-editor();

          }

        }
        /*................ simple pages - bottom ...............*/

        /*................ webform - top ...............*/
        .node-webform{
          .webform-client-form{
            input[type="text"], input[type="email"], textarea{
              width: 350px;
              height: 40px;
              padding-left: 10px;
              border:1px solid $blue;
              margin-bottom: 7px;
              @include respond-to(320){
                width: 280px;
              }
            }
            textarea{
              height: 110px;
            }
            input[type="submit"]{
              margin-top: 10px;
              @include link();
            }
          }
        }

        /*................ webform - bottom ...............*/

        //search results - top
        .view-id-search.view-display-id-page{
          .view-content{
            .views-row{
              border-bottom: 1px solid #ccc;
              margin-top: 20px;
              padding-bottom: 10px;
              .views-field-title{
                a{
                  text-transform: uppercase;
                  font-weight: 700;
                  text-decoration: underline;
                  color: $blue;
                  &:hover{
                    color: $red;
                    @include transite();
                  }
                }
              }
              .text{
                a.views-more-link{
                  color: $blue;
                  text-decoration: underline;
                  &:hover{
                    color: $red;
                    @include transite();
                  }
                }
              }
            }
          }
          .item-list{
            @include item-list();
          }
        }//search results - bottom

        /*............ faq - top ............*/
        .view-faq.view-display-id-page{
          .view-content{
            .views-row{
              margin-bottom: 10px;
              .views-field-title{
                color: $title;
                text-transform: uppercase;
                font-weight: 700;
                cursor: pointer;
                margin-bottom: 5px;
                &:hover{
                  color: $blue;
                  @include transite();
                }
              }

              .views-field-body{
                .answer{
                  display: none;
                  color: $title;
                }
              }
            }
          }
        }

        /*............ faq - end ............*/

        /*............ search results page - top ............*/
        .view-allsearch.view-display-id-page{
          .view-content{
            .views-row{
              border-bottom: 1px solid #ccc;
              margin-bottom: 10px;
              padding-bottom: 10px;

              .views-field-catalogue-name, .views-field-search-title{
                a{
                  color: $blue;
                  font-weight: 700;
                  display: block;
                  margin-bottom: 5px;
                  &:hover{
                    color: $red;
                    @include transite();
                  }
                }
              }
              .views-field-catalogue-description{
                a{
                  color: $blue;
                  text-decoration: underline;
                  &:hover{
                    color: $red;
                    @include transite();
                  }
                }
              }
              .views-field-search-body-value{
                .field-content{
                  p a{
                    color: $blue;
                    text-decoration: underline;
                    &:hover{
                      color: $red;
                      @include transite();
                    }
                  }
                }
              }
            }
          }
          .item-list{
            @include item-list();
          }
        }

        /*............ search results page - end ............*/

      }//content - end

    }//block-system-main - end

    /*............ faq webform - top .............*/
    #block-webform-client-block-46{
      margin-bottom: 10px;
      .block-title{
        font-size: 18px;
        text-transform: uppercase;
        color: $title;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .content{
        input[type="text"], input[type="email"], textarea{
          width: 380px;
          height: 40px;
          padding-left: 10px;
          border:1px solid $blue;
          margin-bottom: 5px;
          @include respond-to(320){
            width: 280px;
          }
        }
        textarea{
          height: 100px;

        }

        input[type="submit"]{
          @include link();
        }
      }
    }
    /*............ faq webform - end .............*/

  }//container - end
}//main__content - end

.region-whatsapp{
  #block-block-15{
    width: 67px;
    height: 74px;
    background-image: url("../img/icons/whatsapp.png");
    background-repeat: no-repeat;
    position: fixed;
    z-index:100;
    bottom: 150px;
    left: calc(50% + 450px);
    cursor: pointer;
    font-size: 42px;
    color: #fff;
    transform-origin: 50% 50%;
    transform: scale(.7);
    //visibility: hidden;
    //opacity: 0;
    @include respond-to(768){
      left: calc(50% + 280px);
    }
    @include respond-to(320){
      left: calc(50% + 90px);
    }

    @include transite(all);

    &.is-active{
      transform: scale(1);
      visibility: visible;
      opacity: 1;
    }

    .content{
      p{
        a{
          opacity:0;
        }
      }
    }
  }

  //firefox

  #block-block-16{
    width: 67px;
    height: 74px;
    background-image: url("../img/icons/whatsapp.png");
    background-repeat: no-repeat;
    position: fixed;
    z-index:100;
    bottom: 150px;
    left: calc(50% + 450px);
    cursor: pointer;
    font-size: 42px;
    color: #fff;
    transform-origin: 50% 50%;
    transform: scale(.7);
    //visibility: hidden;
    //opacity: 0;
    @include respond-to(768){
      left: calc(50% + 280px);
    }
    @include respond-to(320){
      left: calc(50% + 90px);
    }

    @include transite(all);

    &.is-active{
      transform: scale(1);
      visibility: visible;
      opacity: 1;
    }

    .content{
      p{
        a{
          opacity:0;
        }
      }
    }
  }
}