html, body, div, span, applet, object, iframe,h1, h2, h3, h4, h5,
h6, p, blockquote, pre,a, abbr, acronym, address, big,
cite, code,del, dfn, em, img, ins, kbd, q, s, samp,small,
strike, strong, sub, sup, tt, var,b, u, i, center,dl, dt,
dd, ol, ul, li,fieldset, form, label, legend,table, caption,
tbody, tfoot, thead, tr, th, td,article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary,time, mark, audio, video{
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

html{
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*{
  box-sizing: border-box;
}

img,fieldset, a img{
  border: none;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea{
  -webkit-appearance: none;
}

input[type="submit"],
button{
  cursor: pointer;

  &::-moz-focus-inner{
    padding: 0;
    border: 0;
  }
}

textarea{
  overflow: auto;
}

input, button{
  margin: 0;
  padding: 0;
  border: 0;
}

div, input, textarea, select,button,
h1,h2,h3,h4,h5,h6,a,span,a:focus{
  outline: none;
}

table,
thead,
tbody,
tfoot,
tr,
th,
td {
  //word-wrap: break-word;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  border: none;
  border-collapse: inherit;
  border-spacing: 0;
  border-color: inherit;
  vertical-align: inherit;
  text-align: left;
  font-weight: inherit;
  -webkit-border-horizontal-spacing: 0;
  -webkit-border-vertical-spacing: 0;
}

ul,ol{
  list-style-type: none;
}

.views-exposed-widgets{
  margin: 0;
}

.views-exposed-form .views-exposed-widget{
  padding: 0;
}

.views-exposed-form .views-exposed-widget .form-submit{
  margin: 0;
}

.form-item, .form-actions{
  margin: 0;
}

input[type="submit"]{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a{
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  outline: 0;

  &:hover{
    text-decoration: none;
    outline: none;
  }

  &:focus{
    text-decoration: none;
    outline: none;
  }
}

ul, ul li.leaf, ul li.expanded, ul li.collapsed{
  list-style: none;
  margin: 0;
  padding: 0;
}

input, textarea{
  outline: none;
}

//div, p, ul, li{
//  appearance: none;
//  -webkit-tap-highlight-color: rgba(0,0,0,0);
//  -webkit-tap-highlight-color: transparent;
//  -webkit-touch-callout: none;
//  -webkit-user-select: none;
//  -khtml-user-select: none;
//  -moz-user-select: none;
//  -ms-user-select: none;
//}

input[type="submit"]{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

input::-moz-focus-inner {
  border: 0;
}

.ajax-progress-throbber { display: none}